var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Tarefas e reuniões pendentes")])]),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.tarefaRealizadaDialog),callback:function ($$v) {_vm.tarefaRealizadaDialog=$$v},expression:"tarefaRealizadaDialog"}},[_c('tarefa-change-status-component',{key:_vm.currentId,attrs:{"prop-status-id":1,"prop-ocorrencia-tarefa-id":_vm.currentId},on:{"on-confirm":function($event){return _vm.onConfirm()},"on-close":function($event){_vm.tarefaRealizadaDialog = !_vm.tarefaRealizadaDialog}}})],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.tarefaCanceladaDialog),callback:function ($$v) {_vm.tarefaCanceladaDialog=$$v},expression:"tarefaCanceladaDialog"}},[_c('tarefa-change-status-component',{key:_vm.currentId,attrs:{"prop-status-id":2,"prop-ocorrencia-tarefa-id":_vm.currentId},on:{"on-confirm":function($event){return _vm.onConfirm()},"on-close":function($event){_vm.tarefaCanceladaDialog = !_vm.tarefaCanceladaDialog}}})],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Tarefas atribuidas a você ")]),_c('v-card-title',[_c('v-select',{attrs:{"items":_vm.filtroStatusOptions,"chips":"","item-text":"text","item-value":"value","label":"Filtro por status","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getTarefaStatusClass(item.text)},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.atribuidasVoceFilters),callback:function ($$v) {_vm.atribuidasVoceFilters=$$v},expression:"atribuidasVoceFilters"}})],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.atribuidasVoceSearch),callback:function ($$v) {_vm.atribuidasVoceSearch=$$v},expression:"atribuidasVoceSearch"}}),_c('v-data-table',{attrs:{"headers":_vm.atribuidasVoceHeader,"items":_vm.atribuidasVoceItemsFiltered,"search":_vm.atribuidasVoceSearch,"footer-props":{
                itemsPerPageOptions:[-1]
              },"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data_hora_agendamento",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.reuniao_tipo_id",fn:function(ref){
              var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.getOcorrenciaTipoReuniao(value))+" ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.ocorrencia",fn:function(ref){
              var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":item.entidade_nome,"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.created_at",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.descricao",fn:function(ref){
              var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(item.status_id == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.tarefaRealizada(item)}}},[_vm._v(" realizar ")]):_vm._e(),(item.status_id == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"error"},on:{"click":function($event){return _vm.tarefaCancelada(item)}}},[_vm._v(" cancelar ")]):_vm._e(),(item.status_id == 1)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"success"}},[_vm._v(" Realizada ")]):_vm._e(),(item.status_id == 2)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"error"}},[_vm._v(" Cancelada ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Tarefas criadas por você ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.filtroStatusOptions,"chips":"","item-text":"text","item-value":"value","label":"Filtro por status","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:_vm.getTarefaStatusClass(item.text)},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.criadasPorVoceFilters),callback:function ($$v) {_vm.criadasPorVoceFilters=$$v},expression:"criadasPorVoceFilters"}}),_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.criadasPorVoceSearch),callback:function ($$v) {_vm.criadasPorVoceSearch=$$v},expression:"criadasPorVoceSearch"}}),_c('v-data-table',{attrs:{"search":_vm.criadasPorVoceSearch,"headers":_vm.criadasPorVoceHeaders,"items":_vm.criadasPorVoceItemsFiltered,"footer-props":{
                itemsPerPageOptions:[-1]
              },"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data_hora_agendamento",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.reuniao_tipo_id",fn:function(ref){
              var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.getOcorrenciaTipoReuniao(value))+" ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.ocorrencia",fn:function(ref){
              var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":item.entidade_nome,"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.created_at",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.descricao",fn:function(ref){
              var item = ref.item;
return [_c('v-textarea',{staticStyle:{"font-size":"0.9em","min-width":"500px"},attrs:{"readonly":"","resize":"","value":item.descricao,"rounded":true}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(item.status_id == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.tarefaRealizada(item)}}},[_vm._v(" realizar ")]):_vm._e(),(item.status_id == 0)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"error"},on:{"click":function($event){return _vm.tarefaCancelada(item)}}},[_vm._v(" cancelar ")]):_vm._e(),(item.status_id == 1)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"success"}},[_vm._v(" Realizada ")]):_vm._e(),(item.status_id == 2)?_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":"","color":"error"}},[_vm._v(" Cancelada ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }