import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, ocorrenciaTarefaStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import TarefaChangeStatusComponent from '@/components/TarefaChangeStatusComponent.vue';
import _ from 'lodash';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let Caixa = class Caixa extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.alunoSelected = null;
        this.atribuidasVoceFilters = [0];
        this.criadasPorVoceFilters = [0];
        this.filtroStatusOptions = [
            { text: 'Pendente', value: 0 },
            { text: 'Realizada', value: 1 },
            { text: 'Cancelada', value: 2 },
        ];
        this.atribuidasVoceHeader = [
            { text: 'Criado por', value: 'criado_por_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Aluno', value: 'ocorrencia' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: 'Agendada para', value: 'data_hora_agendamento' },
            { text: '', value: 'actions' },
        ];
        this.criadasPorVoceHeaders = [
            { text: 'Responsável', value: 'responsavel_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Aluno', value: 'ocorrencia' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: 'Agendada para', value: 'data_hora_agendamento' },
            { text: '', value: 'actions' },
        ];
        this.items = [];
        this.currentId = null;
        this.tarefaRealizadaDialog = false;
        this.tarefaCanceladaDialog = false;
        this.currentItemObservacao = '';
        this.exibirEncerradas = false;
        this.atribuidasVoceItems = [];
        this.atribuidasVoceSearch = '';
        this.criadasPorVoceItems = [];
        this.criadasPorVoceSearch = '';
    }
    get atribuidasVoceItemsFiltered() {
        return _.filter(this.atribuidasVoceItems, (i) => this.atribuidasVoceFilters.includes(i.status_id));
    }
    get criadasPorVoceItemsFiltered() {
        return _.filter(this.criadasPorVoceItems, (i) => this.criadasPorVoceFilters.includes(i.status_id));
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get itemsList() {
        const list_to_return = [];
        if (this.items.length > 0) {
            list_to_return[0] = this.items[0].filter(this.exibirEncerradasFilter);
            list_to_return[1] = this.items[1].filter(this.exibirEncerradasFilter);
        }
        return list_to_return;
    }
    exibirEncerradasFilter(value) {
        if (!this.exibirEncerradas) {
            return value.status_id == 1;
        }
        else {
            return true;
        }
    }
    getTarefaStatusClass(status) {
        switch (status) {
            case 'Realizada':
                return 'success';
            case 'Pendente':
                return '';
            case 'Cancelada':
                return 'error';
        }
    }
    async tarefaRealizada(item) {
        this.currentId = item.id;
        this.tarefaRealizadaDialog = true;
    }
    async tarefaCancelada(item) {
        this.currentId = item.id;
        this.tarefaCanceladaDialog = true;
    }
    async onConfirm() {
        const items = await ocorrenciaTarefaStore.getOcorrenciaTarefasByUserId(this.userProfile.id);
        this.atribuidasVoceItems = items.atribuidas_a_voce;
        this.criadasPorVoceItems = items.criadas_por_voce;
    }
    async carregaDados() {
        this.loading = true;
        const items = await ocorrenciaTarefaStore.getOcorrenciaTarefasByUserId(this.userProfile.id);
        this.atribuidasVoceItems = items.atribuidas_a_voce;
        this.criadasPorVoceItems = items.criadas_por_voce;
        this.loading = false;
    }
    async mounted() {
        await this.carregaDados();
    }
};
Caixa = __decorate([
    Component({
        components: {
            ClickableCard,
            SearchEntidadeComponent,
            ShowPopup,
            TarefaChangeStatusComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
        },
    })
], Caixa);
export default Caixa;
